import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import getConfig from 'next/config'

const getReleaseStage = () => {
  let env = 'production'

  if (process.browser) {
    if (location.hostname.includes('localhost')) {
      return 'local'
    } else if (location.hostname.includes('dev') || location.hostname.includes('sasaya')) {
      return 'development'
    }

    return 'production'
  }

  return process.env.BUGSNAG_STAGE || process.env.NODE_ENV || 'local'
}

const releaseStage = getReleaseStage()

const client = Bugsnag.start({
  apiKey: 'a9a371db79cd48292a2acc93535bf885',
  plugins: [new BugsnagPluginReact(React)],
  releaseStage,
  enabledReleaseStages: ['development', 'production'],
  notifyReleaseStages: ['development', 'production'],
})

export default client
